// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    usersData: [],
    userPermissions: [],
    userName: "User",

    resetAdminUser:[]
  },
  reducers: {
    usersData: (state, action) => {
      state.usersData = action.payload
    },
    userPermissions: (state, action) => {
      state.userPermissions = action.payload
    },
    mainUserName: (state, action) => {
      state.userName = action.payload
    },
    resetAdminUser: state => {

      state.usersData = []
      state.userPermissions = []
      state.userName = []
      
    },
}
})

export const {resetAdminUser, usersData, mainUserName, userPermissions } = userSlice.actions

export default userSlice.reducer