import axios from "axios";
import { API_URLS } from "../../endpoints/endpoints";

export const getProductByIdService = async (productId) => {
    try {
      const response = await axios.get(`${API_URLS.GET_PRODUCT_BY_ID}/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch product');
    }
  };