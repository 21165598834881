import { axios, Headers } from "../../../../@core/auth/jwt/jwtService";

import { API_URLS } from "../../endpoints/endpoints";

export const getStartSellingPageData = async () => {
    const config = {
        headers: Headers,
    };

    const response = await axios.get(API_URLS.GET_START_SELLING_DATA, config);
    const data = await response.data;
    return data;
};

export const contactUsFormData = async (contactData) => {
    const config = {
        headers: Headers,
    };

    const response = await axios.post(API_URLS.CONTACT_US_FORM, contactData, config);
    return await response.data;
};

export const contactUsFaqs = async () => {
    const config = {
        headers: Headers,
    };

    const response = await axios.get(API_URLS.CONTACT_US_FAQS, config);
    return await response.data;
};

export const getCustomerSupportPageData = async () => {
    const config = {
        headers: Headers,
    };

    const response = await axios.get(API_URLS.CUSTOMER_SUPPORT_DATA, config);
    return await response.data;
};

const StartSellingService = {
    getStartSellingPageData,
    contactUsFormData,
    contactUsFaqs,
    getCustomerSupportPageData,
};

export default StartSellingService;
