import { axios, Headers } from "../../../../@core/auth/jwt/jwtService";

import { API_URLS } from "../../endpoints/endpoints";

export const UpdateUserPassword = async (payload) => {
    const response = await axios.patch(API_URLS.UPDATE_USER_PASSWORD,payload, Headers);
    const data = await response.data;
    return data;
};
export const RemoveUserAddress = async (id) => {
    const response = await axios.delete(`${API_URLS.REMOVE_USER_ADDRESS}/${id}`, Headers);
    const data = await response.data;
    return data;
};
export const DefaultUserAddress = async (id) => {
    const response = await axios.patch(`${API_URLS.SET_DEFAULT_USER_ADDRESS}/${id}`, Headers);
    const data = await response.data;
    return data;
};
export const getUserAddress = async () => {
    const response = await axios.get(API_URLS.GET_USER_ADDRESS, Headers);
    const data = await response.data;
    return data;
};

export const getUserDashboardData = async () => {
    const response = await axios.get(API_URLS.GET_USER_DASHBOARD, Headers);
    const data = await response.data;
    return data;
};
export const getUserProfileData = async () => {
    const response = await axios.get(API_URLS.GET_USER_PROFILE, Headers);    
    const data = await response.data;
    return data;
};

const UserPortalService = {
    UpdateUserPassword,
    getUserAddress, RemoveUserAddress, DefaultUserAddress,
    getUserDashboardData,

    getUserProfileData
};

export default UserPortalService;
