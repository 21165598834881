// ** Reducers Imports
import layout from "./layout"
import navbar from "./Reducers/navbar"
import user from "./Reducers/userRedux"
import authentication from "./Reducers/authentication"
import { combineReducers } from "redux";
import startSellingReducer from '../utility/api/services/StartSelling/StartSellingSlice';
import UserSlice from "../utility/api/services/UserPortal/UserSlice";
import validateUserReducer from '../utility/api/services/ValidateUser/ValidateUserSlice';
import productReducer from "../utility/api/services/Products/ProductSlice"
import fbmApprovalReducer from '../utility/api/services/fbmApprovalSlice/approvalFbmSlice';

const rootReducer = combineReducers({ 
    navbar,
    layout,
    authentication,
    user,
    startSelling: startSellingReducer,
    userPortal:UserSlice,
    validateUser: validateUserReducer,
    product: productReducer,
    fbmApproval: fbmApprovalReducer,
});

export default rootReducer;
