import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserPortalService from './UserService';

const initialState = {
    isLoading: false,
    userAddress: [],
    userDashboard: [],
    userProfile: [],
    error: null,
};

// Async action to get the start selling page data
export const ChangeUserPassword = createAsyncThunk(
    'user/updatePassword',
    async (payload, { rejectWithValue }) => {
        try {
            console.log('PAYLOAD', payload)
            const data = await UserPortalService.UpdateUserPassword(payload);
            return data;
        } catch (error) {
            
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Please try again');
        }
    }
);
export const RemoveAddress = createAsyncThunk(
    'user/removeAddress',
    async (payload, { rejectWithValue }) => {
        try {
            console.log('PAYLOAD', payload)
            const data = await UserPortalService.RemoveUserAddress(payload);
            return data;
        } catch (error) {
            
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Please try again');
        }
    }
);
export const DefaultAddress = createAsyncThunk(
    'user/defaultAddress',
    async (payload, { rejectWithValue }) => {
        try {
            console.log('PAYLOAD', payload)
            const data = await UserPortalService.DefaultUserAddress(payload);
            return data;
        } catch (error) {
            
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Please try again');
        }
    }
);
export const getUserAddressData = createAsyncThunk(
    'user/userAddress',
    async (_, { rejectWithValue }) => {
        try {
            const data = await UserPortalService.getUserAddress();
            return data;
        } catch (error) {
            console.log("ERR", error)
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Please Try Again');
        }
    }
);

export const getDashboardData = createAsyncThunk(
    'user/getDshboardData',
    async (_, { rejectWithValue }) => {
        try {
            const data = await UserPortalService.getUserDashboardData();
            return data;
        } catch (error) {
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Please try again');
        }
    }
);
export const getProfileData = createAsyncThunk(
    'user/getProfileData',
    async (_, { rejectWithValue }) => {
        try {
            const data = await UserPortalService.getUserProfileData();
            return data;
        } catch (error) {
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Please try again');
        }
    }
);

const userSlice = createSlice({
    name: 'userPortal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // address
            .addCase(getUserAddressData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getUserAddressData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userAddress = action.payload;
            })
            .addCase(getUserAddressData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Custom error message from rejectWithValue
            })
            // dashboard
            .addCase(getDashboardData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userDashboard = action.payload;
            })
            .addCase(getDashboardData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Custom error message from rejectWithValue
            })
            // user profile
            .addCase(getProfileData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getProfileData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userProfile = action.payload;
            })
            .addCase(getProfileData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; // Custom error message from rejectWithValue
            });
    },
});

export default userSlice.reducer;
