import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ValidateUserService from './ValidateUserService';

const initialState = {
    isLoading: false,
    email: '',
    roles: [],
    codeVerified: false,
    error: null,
    registrationSuccess: false,
};

// Async action to validate user email
export const validateUserEmail = createAsyncThunk(
    'validateUser/validateUserEmail',
    async (email, { rejectWithValue }) => {
        try {
            const data = await ValidateUserService.validateEmail(email);
            return data;
        } catch (error) {
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Failed to validate email. Please try again later.');
        }
    }
);

export const sendEmailCode = createAsyncThunk(
    'validateUser/sendEmailCode',
    async (email, { rejectWithValue }) => {
        try {
            const data = await ValidateUserService.sendCode(email);
            return data;
        } catch (error) {
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Failed to get email code. Please try again later.');
        }
    }
);

export const verificationEmailCode = createAsyncThunk(
    'validateUser/verifyEmailCode',
    async (registrationParam, { rejectWithValue }) => {
        try {
            const data = await ValidateUserService.verifyEmailCode(registrationParam);
            return data;
        } catch (error) {
            return rejectWithValue('Failed to verify Email Code. Please try again later.');
        }
    }
);

export const postRegistrationFormData = createAsyncThunk(
    'validateUser/postRegistrationFormData',
    async (verificationParam, { rejectWithValue }) => {
        try {
            const data = await ValidateUserService.registrationFormData(verificationParam);
            return data;
        } catch (error) {
            return rejectWithValue('Failed to post Registration Form data. Please try again later.');
        }
    }
);

const ValidateUserSlice = createSlice({
    name: 'validateUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(validateUserEmail.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(validateUserEmail.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload && action.payload.data) {
                    state.email = action.payload.data.email || ''; // Fallback to empty string
                    state.roles = action.payload.data.roles || [];  // Fallback to empty array
                }
            })
            .addCase(validateUserEmail.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(sendEmailCode.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.codeVerified = false;
            })
            .addCase(sendEmailCode.fulfilled, (state, action) => {
                state.isLoading = false;
                state.codeVerified = true;
            })
            .addCase(sendEmailCode.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.codeVerified = false;
            })
            .addCase(verificationEmailCode.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(verificationEmailCode.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload && action.payload.success) {
                    state.codeVerified = true;
                }
            })
            .addCase(verificationEmailCode.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(postRegistrationFormData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.registrationSuccess = false;
            })
            .addCase(postRegistrationFormData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
                if (action.payload && action.payload.success) {
                    state.registrationSuccess = true;
                }
            })
            .addCase(postRegistrationFormData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.registrationSuccess = false;
            });
    },
});

export default ValidateUserSlice.reducer;
