import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProductByIdService } from "./ProductService";


const initialState = {

    product: null,
    isLoading: false,
    error: null,
    filteredProducts: null

};

export const getProductById = createAsyncThunk('product/getProductById', async (productId, { rejectWithValue }) => {
    try {
        const response = await getProductByIdService(productId)
        return response.data
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

const productSlice = createSlice({

    name: "product",
    initialState,
    reducers: {

        setFilteredProducts: (state, action) => {

            state.filteredProducts = action.payload
        }

    },
    extraReducers: builder => {
        builder.addCase(getProductById.pending, (state) => {
            state.isLoading = true
            state.error = null
        }),
        builder.addCase(getProductById.fulfilled, (state, action) => {
            state.isLoading = false
            state.product = action.payload
        }),
        builder.addCase(getProductById.rejected, (state, action) => {
            state.isLoading = false
            state.error = action.payload
        })
    }

})

export const { setFilteredProducts } = productSlice.actions

export default productSlice.reducer