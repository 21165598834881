import { axios, Headers } from "../../../../@core/auth/jwt/jwtService";

import { API_URLS } from "../../endpoints/endpoints";

export const validateEmail = async (email) => {
    const config = {
        headers: Headers,
        validateStatus: false,
    };

    const response = await axios.post(API_URLS.VALIDATE_EMAIL, { email }, config);
    return await response.data;
};

export const sendCode = async (email) => {
    const response = await axios.post(API_URLS.SEND_EMAIL_CODE, { email });
    return await response.data;
};

export const verifyEmailCode = async (verificationParam) => {
    const config = {
        headers: Headers,
    };

    const response = await axios.post(API_URLS.EMAIL_VERIFICATION, verificationParam, config);
    return await response.data;
};

export const registrationFormData = async (registrationParam) => {
    const config = {
        headers: Headers,
    };

    const response = await axios.post(API_URLS.REGISTRATION_FORM, registrationParam, config);
    return await response.data;
};

const ValidateUserService = {
    validateEmail,
    sendCode,
    verifyEmailCode,
    registrationFormData,
};

export default ValidateUserService;
