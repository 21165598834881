import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import StartSellingService from './StartSellingService';

const initialState = {
    isLoading: false,
    startSellingData: [],
    error: null,
    formSubmitted: false,
    contactUsFaqData: [],
    customerSupportData: [],
};

// Async action to get the start selling page data
export const getStartSellingData = createAsyncThunk(
    'StartSelling/getStartSellingData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await StartSellingService.getStartSellingPageData();
            return response;
        } catch (error) {
            // Handle and return the error using rejectWithValue
            return rejectWithValue('Failed to get start selling page data. Please try again later.');
        }
    }
);

export const submitContactUsForm = createAsyncThunk(
    'StartSelling/submitContactUsForm',
    async (contactData, { rejectWithValue }) => {
        try {
            const response = await StartSellingService.contactUsFormData(contactData);
            return response;
        } catch (error) {
            return rejectWithValue('Failed to submit contact us form. Please try again later.');
        }
    }
);

export const getContactUsFaqs = createAsyncThunk(
    'StartSelling/getContactUsFaqs',
    async (_, { rejectWithValue }) => {
        try {
            const response = await StartSellingService.contactUsFaqs();
            return response;
        } catch (error) {
            return rejectWithValue('Failed to get contact us faqs data. Please try again later.');
        }
    }
);

export const getCustomerSupportData = createAsyncThunk(
    'StartSelling/getCustomerSupportData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await StartSellingService.getCustomerSupportPageData();
            return response;
        } catch (error) {
            return rejectWithValue('Failed to get customer support data. Please try again later.');
        }
    }
);

const StartSellingSlice = createSlice({
    name: 'startSelling',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStartSellingData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getStartSellingData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.startSellingData = action.payload;
            })
            .addCase(getStartSellingData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(submitContactUsForm.pending, (state) => {
                state.isLoading = true;
                state.formSubmitted = false;
                state.error = null;
            })
            .addCase(submitContactUsForm.fulfilled, (state) => {
                state.isLoading = false;
                state.formSubmitted = true;
            })
            .addCase(submitContactUsForm.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getContactUsFaqs.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getContactUsFaqs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.contactUsFaqData = action.payload;
            })
            .addCase(getContactUsFaqs.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            .addCase(getCustomerSupportData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getCustomerSupportData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.customerSupportData = action.payload;
            })
            .addCase(getCustomerSupportData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export default StartSellingSlice.reducer;
