// RoleContext.js
import React, { createContext, useState } from 'react';

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState('');
  const [backendRoles, setBackendRoles] = useState([]);

  return (
    <RoleContext.Provider value={{ role, setRole, backendRoles, setBackendRoles }}>
      {children}
    </RoleContext.Provider>
  );
};
