import { createSlice } from "@reduxjs/toolkit";

const approvalFbmSlice = createSlice({
    name: "approval",
    initialState: { isApproved: false },
    reducers: {
        setApproved: (state, action) => {
            state.isApproved = action.payload;
        },
    },
});

export const { setApproved } = approvalFbmSlice.actions;
export default approvalFbmSlice.reducer;
